const isCart = document
  .querySelector('body')
  .classList.contains('woocommerce-cart');

if (isCart) {
  const modifyBtn = document.querySelectorAll('.modify');

  modifyBtn?.forEach((btn) => {
    btn.addEventListener('click', () => {
      const url = btn.getAttribute('data-url');
      window.location.href = url;
    });
  });
}
